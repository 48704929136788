import './EasySoftwareBenefits.scss'

import React, { FunctionComponent } from 'react'
import PuxImageBox, {
  PuxImageBoxType,
} from 'Shared/components/PuxImageBox/PuxImageBox'

export interface EasySoftwareBenefitsType {
  displayText: string
  benefitsList: {
    contentItems: [PuxImageBoxType]
  }
}

const rootClass = `benefits`

const EasySoftwareBenefits: FunctionComponent<EasySoftwareBenefitsType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
      <div className={`${rootClass}-items`}>
        {props.benefitsList &&
          props.benefitsList.contentItems &&
          props.benefitsList.contentItems.map((item) => (
            <PuxImageBox key={item?.contentItemId} columns={4} {...item} />
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareBenefits
