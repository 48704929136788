import './PuxNumber.scss'

import React, { FunctionComponent } from 'react'
import { getIsoCodesWithPrefixPublic } from 'Shared/utils/localeURL'

import Circle from '../helper/Circle'

export interface PuxNumberType {
  numberDescription: string
  numberPrefix: string
  numberUnit: string
  numberValue: number
  numberShowProgress: boolean
  contentItemId: string
}

const PuxNumber: FunctionComponent<PuxNumberType> = (props) => {
  const IsoCode =
    getIsoCodesWithPrefixPublic().filter(
      (iso) => iso.locale === process.env.LOCALE
    )[0].isoCode ?? `en-GB`

  const prefix = `${props.numberPrefix ? props.numberPrefix + ` ` : ``}`
  const unit = `${props.numberUnit ? props.numberUnit : ``}`
  const progressNumber = prefix + props.numberValue + unit
  const formatedNumber =
    prefix + new Intl.NumberFormat(IsoCode).format(props.numberValue) + unit

  return (
    <div className='number'>
      {props.numberShowProgress && (
        <div className='number-progress'>
          <Circle
            customClass={`number-progress-background`}
            progress={100}
            radius={60}
            stroke={5}
          />
          <Circle
            customClass={`number-progress-main`}
            progress={props.numberValue}
            radius={60}
            stroke={5}
          />
          <div className='number-progress-value'>{progressNumber}</div>
        </div>
      )}
      {!props.numberShowProgress && (
        <div className='number-value'>{formatedNumber}</div>
      )}
      <div className='number-description'>
        {props.numberDescription ? props.numberDescription : ``}
      </div>
    </div>
  )
}

export default PuxNumber
