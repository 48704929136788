import './PuxImageBox.scss'

import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from 'Shared/components/PuxButton/PuxButton'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export enum PuxImageBoxLayout {
  VERTICAL = `vertical`,
  HORIZONTAL = `horizontal`,
}

export interface PuxImageBoxType {
  columns?: number
  layout?: PuxImageBoxLayout
  contentItemId: string
  displayText: string
  imageBoxImage: PuxMediaSelectorType
  imageBoxContent: PuxWysiwygHtmlBodyType
  imageBoxButton: PuxButtonType
  imageBoxLightbox?: boolean
}

const rootClass = `imagebox`
const DEFAULT_COLUMNS = 3
const DEFAULT_LAYOUT: PuxImageBoxLayout = PuxImageBoxLayout.VERTICAL

const PuxImageBox: FunctionComponent<PuxImageBoxType> = (props) => {
  const columns: number = props.columns ? props.columns : DEFAULT_COLUMNS
  const layout: PuxImageBoxLayout = props.layout ? props.layout : DEFAULT_LAYOUT

  return (
    <div
      className={`${rootClass} ${rootClass}-${layout} ${rootClass}-${columns}col`}
    >
      <div className={`${rootClass}-image`}>
        <PuxMediaSelector {...props.imageBoxImage} height={150} width={150} />
      </div>

      <div className={`${rootClass}-text`}>
        {(() => {
          if (props.displayText && props.displayText.length) {
            return <h4 className={`${rootClass}-title`}>{props.displayText}</h4>
          }
        })()}

        <div className={`${rootClass}-content`}>
          <PuxWysiwyg content={props.imageBoxContent} />
        </div>
        <PuxButton {...props.imageBoxButton} />
      </div>
    </div>
  )
}

export default PuxImageBox
