import './EasySoftwareSolutions.scss'

import React, { FunctionComponent } from 'react'

import PuxImageBox, { PuxImageBoxType } from '../PuxImageBox/PuxImageBox'

export interface EasySoftwareSolutionsType {
  displayText: string
  solutionsList: {
    contentItems: [PuxImageBoxType]
  }
  solutionsImageSize: string
}

const rootClass = `solutions`

const EasySoftwareSolutions: FunctionComponent<EasySoftwareSolutionsType> = (
  props
) => (
  <div className={rootClass}>
    {(() => {
      if (props.displayText && props.displayText.length) {
        return <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
      }
    })()}

    <div
      className={`${rootClass}-items ${props.solutionsImageSize ?? `is-small`}`}
    >
      {props.solutionsList &&
        props.solutionsList.contentItems &&
        props.solutionsList.contentItems.map((item) => (
          <PuxImageBox key={item.contentItemId} {...item} />
        ))}
    </div>
  </div>
)

export default EasySoftwareSolutions
