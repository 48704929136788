import './PuxRating.scss'

import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'

export interface PuxRatingType {
  contentItemId: string
  contentType: string // unused
  displayText: string
  ratingStars: number
  ratingMaxStars: number
}

const rootClass = `rating`

const PuxRating: FunctionComponent<PuxRatingType> = (props) => {
  const content = []

  for (let i = 1; i <= props.ratingMaxStars; i++) {
    if (i <= props.ratingStars) {
      content.push(<PuxIconBuilder icon='StarActiveIcon' key={i} />)
    } else {
      content.push(<PuxIconBuilder icon='StarIcon' key={i} />)
    }
  }

  return (
    <div className={rootClass}>
      <h4 className={`${rootClass}-title`}>{props.displayText}</h4>

      <div className={`${rootClass}-value`}>
        {` `}
        <span className={`${rootClass}-numeric`}>
          {props.ratingStars} / {props.ratingMaxStars}
        </span>
        {content}
      </div>

      <div className='new'>{}</div>
    </div>
  )
}

export default PuxRating
