import React, { FunctionComponent } from 'react'
import { t } from 'ttag'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxButton, {
  PuxButtonColor,
  PuxButtonStyle,
} from '../PuxButton/PuxButton'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface PuxKeyValueType {
  contentItemId: string
  displayText: string
  keyValueContent: PuxWysiwygHtmlBodyType
}
export interface EasySoftwareCaseStudyItemType {
  contentItemId: string
  contentType: string // unused
  displayText: string
  path: string
  caseStudyPerex: PuxWysiwygHtmlBodyType
  caseStudyImage: PuxMediaSelectorType
  caseStudyParameters: {
    contentItems: [PuxKeyValueType]
  }
}

const rootClass = `case-study-item`

const EasySoftwareCaseStudyItem: FunctionComponent<EasySoftwareCaseStudyItemType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <div className={`${rootClass}-image`}>
        <PuxMediaSelector {...props.caseStudyImage} />
      </div>
      <div className={`${rootClass}-text`}>
        <h3 className={`${rootClass}-title`}>{props.displayText}</h3>
        <div className={`${rootClass}-parameters`}>
          {props.caseStudyParameters &&
            props.caseStudyParameters.contentItems &&
            props.caseStudyParameters.contentItems.map((item) => (
              <div
                className={`${rootClass}-parameter`}
                key={item.contentItemId}
              >
                <strong className={`${rootClass}-label`}>
                  {item.displayText}:
                </strong>
                <PuxWysiwyg inline content={item.keyValueContent} />
              </div>
            ))}
        </div>
        <div className={`${rootClass}-perex`}>
          <PuxWysiwyg content={props.caseStudyPerex} />
        </div>
        <PuxButton
          buttonType={PuxButtonStyle.LINK}
          buttonColor={PuxButtonColor.PRIMARY}
          buttonLink={{
            url: [props.path],
            internal: [true],
            text: [t`Redmine.CaseStudy.ReadMore`],
          }}
        />
      </div>
    </div>
  )
}

export default EasySoftwareCaseStudyItem
