import './EasySoftwareFeatures.scss'

import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxImageBox, {
  PuxImageBoxLayout,
  PuxImageBoxType,
} from '../PuxImageBox/PuxImageBox'

export interface EasySoftwareFeaturesType {
  displayText: string
  featuresPerex: PuxWysiwygHtmlBodyType
  featuresMainButton: PuxButtonType
  featuresSecondaryButton: PuxButtonType
  featuresList: {
    contentItems: [PuxImageBoxType]
  }
}

const rootClass = `features`

const EasySoftwareFeatures: FunctionComponent<EasySoftwareFeaturesType> = (
  props
) => (
  <div className={rootClass}>
    <h2 className={`${rootClass}-title`}>{props.displayText}</h2>

    <div className={`${rootClass}-perex perex`}>
      <PuxWysiwyg content={props.featuresPerex} />
    </div>

    <div className={`${rootClass}-items`}>
      {props.featuresList &&
        props.featuresList.contentItems &&
        props.featuresList.contentItems.map((item) => (
          <PuxImageBox
            key={item.contentItemId}
            layout={PuxImageBoxLayout.HORIZONTAL}
            columns={2}
            {...item}
          />
        ))}
    </div>

    <div className={`${rootClass}-buttons`}>
      <PuxButton {...props.featuresMainButton} />
      <PuxButton {...props.featuresSecondaryButton} />
    </div>
  </div>
)

export default EasySoftwareFeatures
