import React, { FunctionComponent } from 'react'

import PuxRating, { PuxRatingType } from '../PuxRating/PuxRating'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareReviewItemType {
  contentItemId: string
  contentType: string // unused
  displayText: string
  reviewContent: PuxWysiwygHtmlBodyType
  reviewReviewerCompany: PuxWysiwygHtmlBodyType
  reviewReviewerName: PuxWysiwygHtmlBodyType
  reviewReviewerPosition: PuxWysiwygHtmlBodyType
  reviewRatingList: {
    contentItems: [PuxRatingType]
  }
}

const rootClass = `review-item`

const EasySoftwareReviewItem: FunctionComponent<EasySoftwareReviewItemType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <div className={`${rootClass}-content`}>
        <h3 className={`${rootClass}-title`}>{props.displayText}</h3>
        <div className={`${rootClass}-text`}>
          <PuxWysiwyg content={props.reviewContent} />
        </div>
        <h4 className={`${rootClass}-name`}>
          {props.reviewReviewerName?.html}
        </h4>
        <div className={`${rootClass}-position`}>
          {props.reviewReviewerCompany?.html}
          {`, ${props.reviewReviewerPosition?.html}`}
        </div>
      </div>
      <div className={`${rootClass}-ratings`}>
        {props.reviewRatingList?.contentItems.map((item, idx) => (
          <PuxRating {...item} key={idx} />
        ))}
      </div>
    </div>
  )
}

export default EasySoftwareReviewItem
