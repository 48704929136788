import './PuxImageContent.scss'

import React, { FunctionComponent } from 'react'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export enum ImageContentButtonAlign {
  LEFT = `left`,
  RIGHT = `right`,
  CENTER = `center`,
}

export interface PuxImageContentType {
  imageContentText: PuxWysiwygHtmlBodyType
  imageContentImagePosition: string
  imageContentButtonAlign: ImageContentButtonAlign
  puxMediaSelector: PuxMediaSelectorType
  puxButton: PuxButtonType
  puxVideo: PuxVideoType
}

const PuxImageContent: FunctionComponent<PuxImageContentType> = (props) => {
  return (
    <div className={`image-content ${props.imageContentImagePosition ?? ``}`}>
      <div className='image-content-image'>
        <PuxMediaSelector {...props.puxMediaSelector} />
        {props.puxVideo?.videoYTID?.html && (
          <PuxVideo
            videoStructuredDataContentUrl={
              props.puxVideo?.videoStructuredDataContentUrl
            }
            videoStructuredDataDescription={
              props.puxVideo?.videoStructuredDataDescription
            }
            videoStructuredDataName={props.puxVideo?.videoStructuredDataName}
            videoStructuredDataUploadDate={
              props.puxVideo?.videoStructuredDataUploadDate
            }
            videoThumbnail={props.puxVideo?.videoThumbnail}
            videoYTID={props.puxVideo?.videoYTID}
          />
        )}
      </div>
      <div className='image-content-content'>
        <PuxWysiwyg content={props.imageContentText} />
        <div
          className={`image-content-button ${props.imageContentButtonAlign
              ? `button-` + props.imageContentButtonAlign
              : ``
            }`}
        >
          <PuxButton {...props.puxButton} />
        </div>
      </div>
    </div>
  )
}

export default PuxImageContent
