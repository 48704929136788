import './EasySoftwareReviews.scss'

import React, { FunctionComponent } from 'react'
import PuxPicture, {
  PuxPictureType,
} from 'Root/Shared/components/content/mediaImage/PuxPicture'

import PuxCarousel from '../PuxCarousel/PuxCarousel'
import EasySoftwareReviewItem, {
  EasySoftwareReviewItemType,
} from './EasySoftwareReviewItem'

export interface EasySoftwareReviewsType {
  contentItemId?: string
  displayText: string
  reviewsText: string
  reviewsImage: PuxPictureType
  reviewsList: {
    contentItems: [EasySoftwareReviewItemType]
  }
}

const rootClass = `reviews`

const EasySoftwareReviews: FunctionComponent<EasySoftwareReviewsType> = (
  props
) => {
  const items = props.reviewsList?.contentItems
  const slides = items.map((item, idx) => (
    <EasySoftwareReviewItem key={idx} {...item} />
  ))

  return (
    <div className={rootClass}>
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>

      <div className={`${rootClass}-items`}>
        {items?.length <= 1 ? (
          <EasySoftwareReviewItem {...items[0]} />
        ) : (
          <PuxCarousel
            contentItemId={props.contentItemId}
            slidesPerView={1}
            externalNavigation
            slides={slides}
          />
        )}
      </div>

      <div className={`${rootClass}-static`}>
        <h4 className={`${rootClass}-footer`}>{props.reviewsText}</h4>
        <PuxPicture
          {...props.reviewsImage}
          width={90}
          height={60}
          customAlt={props.reviewsText}
        />
      </div>
    </div>
  )
}

export default EasySoftwareReviews
